

// src/App.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
//import './App.css';

function TrainCalc() {
  const [fromScale, setFromScale] = useState('HO');
  const [toScale, setToScale] = useState('N');
  const [conversionResult, setConversionResult] = useState(null);


    const trainScales = [
      { name: 'Z', ratio: 1 / 220 },
      { name: 'N', ratio: 1 / 160 },
      { name: 'TT', ratio: 1 / 120 },
      { name: 'HO', ratio: 1 / 87 },
      { name: 'OO', ratio: 1 / 76 },
      { name: 'S', ratio: 1 / 64 },
      { name: 'O', ratio: 1 / 48 },
      { name: 'LGB', ratio: 1 / 22.5 },
    ];
    
   
  useEffect(() => {
    // Find the selected scales and their ratios
    const fromScaleObject = trainScales.find((scale) => scale.name === fromScale);
    const toScaleObject = trainScales.find((scale) => scale.name === toScale);

    if (fromScaleObject && toScaleObject) {
      // Calculate the conversion result based on the ratios
      const conversionRatio = toScaleObject.ratio / fromScaleObject.ratio;
      const result = (conversionRatio * 100).toFixed(2);
      setConversionResult(result);
    }
  }, [fromScale, toScale]);

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Train Scale Converter</h1>
          <div className="table-container">

          <Form>
            <Form.Group controlId="fromScale">
              <Form.Label>From Scale</Form.Label>
              <Form.Control as="select" value={fromScale} onChange={(e) => setFromScale(e.target.value)}>
                {trainScales.map((scale) => (
                  <option key={scale.name} value={scale.name}>
                    {scale.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="toScale">
              <Form.Label>To Scale</Form.Label>
              <Form.Control as="select" value={toScale} onChange={(e) => setToScale(e.target.value)}>
                {trainScales.map((scale) => (
                  <option key={scale.name} value={scale.name}>
                    {scale.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>

          {conversionResult && (
            <div className="mt-3">
              <strong>Conversion Result:</strong> {conversionResult}%
            </div>
          )}

          <div className="mt-3">
            <h3>Train Scales and Ratios</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Scale</th>
                  <th>Ratio</th>
                </tr>
              </thead>
              <tbody>
                {trainScales.map((scale) => (
                  <tr key={scale.name}>
                    <td>{scale.name}</td>
                    <td>{`1:${Math.round(1 / scale.ratio)}`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default TrainCalc;
