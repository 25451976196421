import Header from './compontents/Header'
import Footer from './compontents/Footer'
import About from './compontents/About'
import Articles from './compontents/Articles'
import Home from './compontents/Home'
import TrainCalc from './compontents/TrainCalc'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


function App() {
  return (
    <Router>
      <div className="container">
        <Header />

        <Routes>
          <Route path='/'
            element={
                 <Home/>
            } />

          <Route path='/about' 
            element={
                  <About />
            } />

          <Route path='/articles' 
            element={
                  <Articles />
            } />

            <Route path='/trains' 
            element={
                  <TrainCalc /> 
            } />
 
 

        </Routes>
        <Footer />
      </div>
    </Router>

  );
}

export default App;
