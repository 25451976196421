import React from 'react';
import { Outlet, Link } from 'react-router-dom';

import mebike from './imgs/IMG_0043.JPEG';

const About = () =>{
   
        return (
                <div>
                <h1>About Me</h1>
                <p>
                <br/>
                Former <a href="https://www.cisco.com/">Cisco</a>, <a href="https://www.ebay.com">Ebay</a> and <a href="https://www.paypal.com">PayPal</a> manager/architect who has lead and build 
                scalable solutions for both internal and external architecture. I love to talk about scaling and architecture challanges that I have seen during my IT career in both the public and private cloud.
                <br/><br/>               
                In my spare time, I have completed numerous half distance IronMan races and 10 IronMan races which
                a huge passion of mine. I can equally talk about these experiences from both a physical and mental point of view.
                </p>
                <img src={mebike} className="photo" alt="Me" />
                <br/> <br/>

            </div>
        )
   
}

export default About;
