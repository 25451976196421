import React from 'react'
import PropType from 'prop-types'


const Header = ( {title, onAdd, showAdd}) => {
  return (
    <header className='header'>
      <h1>{title}</h1>
      
      </header>
  )
}

Header.defaultProps = {

  title: 'Random Thoughts from Me',
}

Header.propTypes = {

  title: PropType.string.isRequired,
}

export default Header



