import React from 'react';
import { Outlet, Link } from 'react-router-dom';

const Articles = () =>{
   
        return (
            <div>
                <Link to="https://www.linkedin.com/pulse/first-things-considerations-external-availability-malkowski-jr-">First Things First - Considerations for External Availability Solutions</Link>
            </div>
        )
   
}

export default Articles;
