import React from 'react'
import { Outlet, Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer>
            <Link to="/">Home</Link> |{' '}
            <Link to="/Articles">Articles</Link>  |{' '}
            <Link to="/about">About</Link> |{' '}
            <Link to="/trains">Train Scale Convertor</Link>
            <p>Copyright &copy; 2024</p>
        </footer>
    )
}

export default Footer
